.videos-container {
  padding-bottom: 5px;
  .video-container-item {
    padding-top: 10px;
    padding-bottom: 10px;
    & > div {
      width: 100%;
      height: 240px;
    }
  }
}

.video-container {
  position: relative;
}

.video-overlay {
  position: absolute;
  top: 10px;
  overflow: visible;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-top-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &-bottom-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
