.server-html {
  line-height: 23px;
  font-size: 16px;
  overflow-wrap: anywhere;

  strong {
    font-family: 'Avenir Next Medium', 'Avenir Next', sans-serif;
    line-height: normal;
    font-weight: bold;

    del {
      font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
    }
  }

  del {
    font-weight: bold;
    font-size: 20px;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
    text-decoration: none;
    font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
  }

  p {
    font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
  }

  h1,
  p,
  b {
    padding: 0;
    margin: 0;
    letter-spacing: normal;
  }

  h1,
  b {
    font-weight: 500;
  }
}

@media only screen and (max-device-width: 468px) and (orientation: portrait) {
  .server-html {
    max-width: 90vw;
  }
}
