.photo-slider {
  width: 100%;
  border-radius: 4px;

  .image-gallery-index {
    background-color: transparent;
    color: #797979;
    margin-top: 10px;
    margin-right: 14px;
    font-size: 16px;
  }

  img.image-gallery-image {
    border-radius: 4px;
  }

  button.image-gallery-icon.image-gallery-left-nav,
  button.image-gallery-icon.image-gallery-right-nav {
    color: #797979;

    svg {
      height: 32px;
    }
  }

  .image-gallery-slides {
    min-height: 75px;
    margin-left: 1px;
    margin-right: 1px;
    .image-gallery-slide {
      .video-container-item {
        padding-top: 0;

        & > div {
          width: 100%;
          height: 427px;
        }
      }
    }
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  .photo-slider {
    .image-gallery-index {
      margin-right: 7px;
    }
    .image-gallery-slides {
      min-height: 75px;
      .image-gallery-slide {
        .video-container-item {
          & > div {
            width: 100%;
            height: 100vw;
          }
        }
      }
    }
  }
}
