.welcome {
  display: flex;
  justify-content: center;

  &-introduction {
    padding: 10px 0;
    h1 {
      margin: 10px 0;
    }
  }
}
