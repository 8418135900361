.menu-item {
  height: 36px;
  padding: 9px 10px 8px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Avenir Next Regular';

  &:hover,
  &.active {
    background-color: white;
  }

  & > .item-icon {
    height: 18px;
    width: 18px;
    background-size: contain;
    margin-right: 10px;

    &.crop-link {
      background-image: url(Crop-Off.png);
    }

    &.thumbnail-link {
      background-image: url(Star-Off.png);
    }

    &.thumbnail-link.active {
      background-image: url(Star-On.png);
    }

    &.delete-link {
      background-image: url(Delete-Off.png);
    }

    &.edit-link {
      background-image: url(../../img/Edit-Off.png);
    }
  }

  &:hover {
    & > .item-icon {
      &.crop-link {
        background-image: url(Crop-On.png);
      }

      &.thumbnail-link {
        background-image: url(Star-On.png);
      }

      &.delete-link {
        background-image: url(Delete-On.png);
      }

      &.edit-link {
        background-image: url(../../img/Edit-On.png);
      }
    }
  }
}
