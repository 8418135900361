.phone-container {
  .phone-input {
    max-width: 200px;
    margin-top: 5px;
    margin-bottom: 20px;

    :global(.selected-flag) {
      outline: none;
    }

    input {
      max-width: 200px;
      height: 38px;
      margin: 5px 0px;
      border: 1px solid rgb(200, 204, 209);
      border-radius: 4px;
      outline: none;
      font-size: 16px;
      padding: 0 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      line-height: normal;
      outline: none;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(200, 204, 209);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgb(200, 204, 209);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgb(200, 204, 209);
      }

      &.error {
        border-color: rgb(222, 45, 99);
      }
    }

    &.error {
      input {
        color: rgb(222, 45, 99);
        border-color: rgb(222, 45, 99);
      }
    }
  }


}
