@font-face {
  font-family: 'Avenir Next Medium';
  src: url('./fonts/AvenirNext-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Regular';
  src: url('./fonts/AvenirNext-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Demibold';
  src: url('./fonts/AvenirNext-DemiBold.otf') format('opentype');
}

html,
body,
div#root {
  height: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }

  & *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}

.app {
  height: 100%;
  background-color: #ffffff;
  font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
  font-size: 16px;
  letter-spacing: normal;
  text-size-adjust: 100%;
  color: rgb(21, 36, 55);
  display: flex;
  flex-direction: column;

  .app-toast {
    border-radius: 5px;
    min-height: 50px;
    font-family: inherit;
    padding-left: 14px;
    padding-right: 14px;
    width: 494px;
    margin-left: -91px;

    button {
      margin-top: 5px;
      color: rgb(21, 36, 55);
    }

    &.Toastify__toast--success {
      color: rgb(21, 36, 55);
      background-color: #dff0d8;
      border: 1px solid #1ec64c;
    }

    &.Toastify__toast--error {
      color: rgb(21, 36, 55);
      background-color: #f6e4ea;
      border: 1px solid rgb(222, 45, 99);
    }
  }

  a {
    color: rgb(21, 36, 55);
    text-decoration-color: rgb(21, 36, 55);
    text-decoration-line: underline;
    text-decoration-style: solid;
  }

  textarea,
  select,
  option,
  input {
    font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
    color: rgb(21, 36, 55);
  }

  &-header {
    font-family: 'Avenir Next Demibold', 'Avenir Next', sans-serif;
    padding: 0px 36px;
    height: 71px;
    flex-shrink: 0;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    border-bottom: 1px rgb(200, 204, 209) solid;
    line-height: 22px;

    div {
      display: flex;
    }

    .logo{
      display: flex;
      max-width: 235px;
      max-height: 40px;
      vertical-align: middle;
    }
    img{
      display: flex;
    }
  }

  &-footer {
    height: 242px;
    min-height: 242px;
    background-color: rgb(21, 36, 55);
    color: rgb(133, 141, 151);
    font-size: 19px;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    & a {
      color: rgb(133, 141, 151);
    }

    & > div {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    &-recaptcha {
      font-size: 11px;

      & > a {
        text-decoration: underline;
      }
    }
  }

  &-content {
    background-color: rgb(245, 246, 247);
    flex-grow: 1;
    padding: 40px;
    padding-top: 70px;
    padding-bottom: 150px;

    &.embed {
      padding: 0;
      background-color: #ffffff;
    }
  }

  h1,
  b {
    font-family: 'Avenir Next Medium', 'Avenir Next', sans-serif;
  }

  div.server-html {
    line-height: 23px;
    overflow-wrap: anywhere;

    strong {
      font-family: 'Avenir Next Medium', 'Avenir Next', sans-serif;
      line-height: normal;
      font-weight: bold;

      del {
        font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
      }
    }

    del {
      font-weight: bold;
      font-size: 20px;
      line-height: normal;
      letter-spacing: normal;
      margin: 0;
      text-decoration: none;
      font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
    }

    p {
      font-family: 'Avenir Next Regular', 'Avenir Next', sans-serif;
    }

    h1,
    p,
    b {
      padding: 0;
      margin: 0;
      letter-spacing: normal;
    }

    h1,
    b {
      font-weight: 500;
    }
  }
}

h1 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 29px;
}

h2 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 29px;
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  .app {
    &-toast {
      margin-left: 0px;
      width: auto;
      border-radius: 0;
    }

    &-content {
      background-color: transparent;
      padding: 0;
      width: auto;
    }

    &-header {
      height: 55px;
      padding-left: 28px;
    }

    &-footer {
      font-size: 17px;
    }
  }
}
