.video-edit-button {
  width: 28px;
  height: 28px;
  margin: 10px;
  cursor: pointer;

  &.edit-active {
    z-index: 12;
  }
  &.edit-inactive {
    z-index: 1;
  }

  img {
    z-index: 12;
    width: 100%;
  }
}
