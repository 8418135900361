.welcome {
  display: flex;
  justify-content: center;

  h1, h2 {
    margin: 10px 0;
  }

  p {
    margin: 3px, 0;
  }

  &-tc-container {
    font-size: 14px;
    padding-top: 50px;
    display: flex;

    & > input {
      margin-right: 10px;
    }
  }

  &-recaptcha-container {
    padding-top: 60px;
    display: flex;
    justify-content: center;
  }

  &-button-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  }

  &-introduction {
    padding: 10px 0;
  }
}
