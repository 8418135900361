.dropdown-container {
  .dropdown-component {
    display: flex;
    align-items: flex-end;
    .dropdown {
      width: 200px;
      height: 38px;
      margin: 5px 0px;
      padding-right: 25px;
      border: 1px solid rgb(200, 204, 209);
      border-radius: 4px;
      outline: none;
      font-size: 16px;
      padding: 0 10px;
      background-color: #ffffff;
      background-size: contain;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      line-height: normal;
      outline: none;
      background-image: linear-gradient(
          45deg,
          transparent 4px,
          rgb(21, 36, 55) 4px
        ),
        linear-gradient(135deg, rgb(21, 36, 55) 4px, transparent 4px),
        linear-gradient(135deg, transparent 4px, rgb(21, 36, 55) 4px),
        linear-gradient(45deg, rgb(21, 36, 55) 4px, transparent 4px);
      background-position: calc(100% - 17px) 19px, calc(100% - 11px) 19px,
        calc(100% - 17px) 11px, calc(100% - 11px) 11px;
      background-size: 5px 6px, 6px 6px, 5px 6px, 6px 6px;
      background-repeat: no-repeat;

      option {
        padding: 20px;
        margin: 20px;
        outline: none;
        border: none;
      }

      &::placeholder,
      option[default] {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(200, 204, 209);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgb(200, 204, 209);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgb(200, 204, 209);
      }

      &.error {
        border-color: rgb(222, 45, 99);
      }
    }
  }
}
