.proposal {
  display: flex;
  justify-content: center;
  .options {
    margin: 40px -9px 0 -9px;
    display: flex;
    flex-wrap: wrap;
    button {
      margin: 5px 7px;
      min-width: 204px;
      width: 100%;
      padding: 10px;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 49px;
  }

  @media only screen and (max-device-width: 468px) and (orientation: portrait) {
    .options {
      justify-content: center;
    }
  }
}
