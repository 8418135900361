.date-container {
  .date-error-message {
    margin-bottom: 20px;
    margin-top: -10px;
    color: rgb(222, 45, 99);
  }
  .date-controls {
    max-width: 200px;
    display: flex;
    justify-content: space-between;

    .date-year {
      max-width: 76px;
    }

    .date-month, .date-day {
      max-width: 56px;
    }
  }
}
