.button-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  min-width: 214px;
  outline: none;
  min-height: 38px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
  cursor: pointer;

  &.toggle-color-blue {
    border-color: #2d97de;
    color: #2d97de;

    &.toggled {
      background-color: #2d97de;
      color: #ffffff;
    }
  }

  &.toggle-color-black {
    border-color: #152437;
    color: #152437;

    &.toggled {
      background-color: #152437;
      color: #ffffff;
    }
  }
  &.toggle-color-gray {
    border-color: #a3a3a3;
    color: #a3a3a3;

    &.toggled {
      background-color: #a3a3a3;
      color: #ffffff;
    }
  }
  &.toggle-color-red {
    border-color: #de2d63;
    color: #de2d63;

    &.toggled {
      background-color: #de2d63;
      color: #ffffff;
    }
  }
  &.toggle-color-green {
    border-color: #21d29c;
    color: #21d29c;

    &.toggled {
      background-color: #21d29c;
      color: #ffffff;
    }
  }
}
