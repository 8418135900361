.photos-container {
  outline: none;

  &-uploading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(245, 246, 247, 0.8);
  }

  .photo-editing-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 997;
    background-color: rgba(245, 246, 247, 0.8);
  }

  &-uploaded-photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .photo {
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      margin-bottom: 10px;
      width: 49%;
      max-width: 49%;
      padding-top: 49%;
      position: relative;
      height: 0;
      overflow: visible;

      .menu-content {
        border-radius: 5px;
        max-width: 160px;
        margin-top: 14px;
        margin-left: 14px;
        padding: 0;
        border: 1px solid rgb(200, 204, 209);
      }

      .drag-over-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border: 4px dashed #858d97;
        border-radius: 4px;
      }

      .photo-overlay {
        position: absolute;
        top: 0;
        overflow: visible;

        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-top-row {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        &-bottom-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        & div.photo-edit-button {
          width: 28px;
          height: 28px;
          margin: 10px;
          cursor: pointer;
          z-index: 12;

          img {
            z-index: 12;
          }
        }

        & div.photo-move-left {
          width: 28px;
          height: 28px;
          margin: 10px;
          cursor: pointer;
          background-size: contain;
          background-image: url(L-Off.png);

          img {
            z-index: 12;
          }

          &:hover {
            background-image: url(L-On.png);
          }
        }

        & div.photo-move-right {
          width: 28px;
          height: 28px;
          margin: 10px;
          cursor: pointer;
          background-size: contain;
          background-image: url(R-Off.png);

          img {
            z-index: 12;
          }

          &:hover {
            background-image: url(R-On.png);
          }
        }
      }

      .photo-container {
        position: absolute;
        top: -1px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 101%;
        border: 1px solid rgb(200, 204, 209);
        border-radius: 4px;
        overflow: hidden;
        min-width: 101%;

        img.lighten {
          opacity: 0.08;
        }
      }

      .attachment-title-text {
        display: flex;
        position: absolute;
        text-align: center;
        word-break: break-all;
        padding: 5px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.empty {
        background-color: rgb(200, 204, 209);
        outline: none;
        border-radius: 4px;
        max-height: 49%;
        max-width: 49%;
        span.icon {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg path {
            fill: #3333;
          }
        }
      }
    }
  }
}

.crop-content {
  max-width: 816px;
  height: 540px;
  border-radius: 5px;
  border: 1px solid rgb(200, 204, 209);
  min-width: 816px;

  .crop-content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 27px 36px;
    height: 100%;
    .popup-header {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      .popup-close-button {
        position: absolute;
        right: 21px;
        top: -42px;

        height: 28px;
        width: 28px;
        background-size: contain;
        background-image: url(Close_Off.png);

        &:hover {
          background-image: url(Close-Over.png);
        }
      }
    }
  }
  .crop-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 220px;
    .crop-buttons {
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .crop-buttons-row {
        display: flex;
        justify-content: space-between;
      }

      .crop-button {
        cursor: pointer;
        color: rgb(133, 141, 151);
        height: 36px;
        width: 46px;
        border: 1px solid rgb(200, 204, 209);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;

        &:hover,
        &.active {
          background-color: #2d97de;
          border: 1px solid #2d97de;
          color: #fff;
        }

        &.crop-button-done {
          width: 100%;
        }
      }
    }

    .crop-original-image {
      width: 220px;
      height: 220px;
      border: 1px solid rgb(200, 204, 209);
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 220px;
        max-height: 220px;
      }
    }
  }

  .crop-canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 9999em rgba(245, 246, 247, 0.8);
    max-width: 487px;
    max-height: 487px;
    height: 100%;
    background-image: url(transparency.png);
    width: 100%;
    img {
      opacity: 1;
      height: 100%;
      width: 100%;
    }
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  .photos-container {
    &-uploaded-photos {
      .photo {
        .menu-content {
          max-width: 150px;

          .menu-item {
            padding-left: 9px;
          }
        }
      }
    }
  }

  .crop-content {
    width: 100%;
    height: 100%;
    min-width: 100%;
    flex-wrap: wrap;
    overflow-y: scroll;

    .crop-content-wrapper {
      margin-top: 55px;
      padding: 0;
      display: block;
      height: 100%;

      .popup-header {
        position: fixed;
        top: 0;
        height: 55px;
        z-index: 99;
        background-color: #ffffff;

        .popup-close-button {
          margin: 10px;
          position: relative;
          right: unset;
          top: unset;
        }
      }
    }

    & .crop-canvas {
      width: 100%;
    }

    & .crop-controls {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .crop-buttons {
        margin-top: 40px;
        margin-bottom: 150px;
        width: 220px;
      }

      .crop-original-image {
        width: 100%;
        margin: 30px 0;
        display: none;

        img {
          width: 100%;
          max-width: none;
          max-height: none;
        }
      }
    }
  }
}
