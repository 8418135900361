.panel {
  &.no-padding {
    padding: 0;
    min-width: unset;
  }

  &.wide {
    max-width: 1030px;
    min-width: 330px;
    width: 100%;
  }

  &.no-border {
    border: none;
  }

  padding: 22px 37px 21px 37px;

  max-width: 494px;
  width: 494px;
  border: 1px #d6d6d6 solid;
  border-radius: 4px;
  background-color: #ffffff;

  &.for-message {
    min-height: 253px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  .panel {
    border: none;
    border-radius: 0px;
    padding: 25px;
  }
}
