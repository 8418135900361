.video-upload {
  margin-top: -1px;

  .video-upload-input-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    & > button.button {
      min-width: 140px;
      margin-bottom: 10px;
    }

    & .input-container {
      flex-shrink: 1;
      flex-grow: 1;
      padding-right: 10px;
      input#video-upload-input {
        margin-bottom: 10px;
      }

      & input {
        width: 100%;
      }
    }
  }
}
