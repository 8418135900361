.delete-profile {
  display: flex;
  justify-content: center;

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
    margin-bottom: 49px;
    button {
      min-width: 201px;
      max-width: 201px;
    }
  }
}

@media (max-width: 600px) {
  .buttons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
