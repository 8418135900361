.welcome {
  display: flex;
  justify-content: center;

  h1.company-name {
    margin-top: 22px;
    margin-bottom: 27px;
  }

  p {
    margin: 3px, 0;
  }

  &-tc-container {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3;
    line-height: 20px;
    outline: none;
    padding-top: 50px;
    display: flex;

    & .tc-error {
      color: rgb(222, 45, 99);

      & a {
        color: rgb(222, 45, 99);
        text-decoration-color: rgb(222, 45, 99);
      }
    }

    & .rc-checkbox {
      margin-top: 3px;
      margin-right: 10px;
    }
  }

  &-button-container {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    padding-bottom: 70px;
  }

  &-introduction {
    padding: 27px 0 10px 0;
  }
}
@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  .welcome {
    .photo-slider {
      border-radius: 0px;

      img.image-gallery-image {
        border-radius: 0px;
      }
    }

    .panel {
      padding: 0;
    }

    &-content {
      padding: 0 30px;
    }

    &-onhold {
      padding: 25px;
    }
  }
}
