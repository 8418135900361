.error-boundary {
  height: 100vh;
  max-width: 640px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  code {
    width: 100%;
    display: block;
    background-color: #fff;
    padding: 20px 15px;
    margin: 20px 0;
  }
}
