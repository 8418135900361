.checkbox-multi-component {
  width: 200px;
  color: rgb(21, 36, 55);
  font-size: 16px;
  margin: 10px 0;
  &.errors .check-box-wrapper ul {
    border-color: #de2d63;
  }
  .check-box-wrapper ul {
    border: 1px solid rgb(200, 204, 209);
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: rgb(245, 246, 247);
    border-radius: 4px;
    li {
      padding-bottom: 8px;
      padding-top: 7px;
      padding-left: 10px;
      padding-right: 10px;
      display: block;
      border-top: 1px solid rgb(200, 204, 209);
      background-color: rgb(245, 246, 247);
      height: auto;
      margin: 0;
      background-color: white;
      border-radius: 0;
      font-size: 14px;
      letter-spacing: normal;
      cursor: pointer;

      // :hover .button-notaddedtoproject{
      //     background-image: url(checkbox-circle-fill-w.svg);
      // }

      // :hover .button-addedtoproject{
      //     background-image: url(checkbox-blank-circle-line.svg);
      // }

      // :hover{
      //     color:#2d97de;
      // }
    }

    :last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    :first-child {
      border-top: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .button-notaddedtoproject {
      width: 18px;
      height: 18px;
      background-repeat: round;
      cursor: pointer;

      // background-image: url();
      position: relative;
    }

    .button-notaddedtoproject {
      width: 18px;
      height: 18px;
      background-repeat: round;
      cursor: pointer;
      background-image: url(checkbox-blank-circle-line.svg);
      position: relative;
      margin-top: -2px;
      top: 4px;
    }

    .button-addedtoproject,
    .button-addedtoproject-no-over {
      width: 18px;
      height: 18px;
      background-repeat: round;
      cursor: pointer;
      background-image: url(checkbox-circle-fill-w.svg);

      border: 0;
      position: relative;
      margin-top: -2px;
      top: 4px;
    }
    .row > div {
      display: inline-flex;
    }
    .col-sm-2 {
      width: 18px;
      margin-right: 10px;
    }
    .col-sm-9 {
      width: 75%;
    }
  }
}
