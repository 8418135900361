.email-confirmed {
  display: flex;
  justify-content: center;
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  .email-confirmed {
    .panel {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 255px;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}
