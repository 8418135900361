.registration-success {
  display: flex;
  justify-content: center;

  .button-container {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  h1,
  h2 {
    margin: 10px 0;
  }
}
