.agreement {
  display: flex;
  justify-content: center;
  .buttons-container {
    display: flex;
    justify-content: center;
    .buttons-centered {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin-top: 27px;
      margin-bottom: 6px;
      max-width: 444px;
      gap: 10px;

      button {
        padding: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .buttons-centered {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    button {
      width: 201px;
    }
  }
}
