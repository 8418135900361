span.MuiCheckbox-root.checkbox {
  &.Mui-checked {
    color: #2d97de;
  }

  svg {
    height: 19px;
  }
  background-color: transparent;
  padding: 0px;
  height: 100%;
  margin-right: 3px;
}
