.register {
  display: flex;
  justify-content: center;

  

  .register-form-group {
    h2:not(:first-child) {
      margin-top: 30px;
    }

    h2 {
      margin-bottom: 24px;
    }
    padding-bottom: 8px;
  }

  &-recaptcha-container {
    // padding-top: 50px;
    display: flex;
    justify-content: center;
  }

  .validation-summary{
    padding-top: 30px;
    margin-bottom: 64px;
    ul{
      padding: 0;
      li{
        text-align: center;
        list-style: none;
        color: #de2d63;
      }
    }
  }
  .validation-summary-short{
    padding-top: 30px;
    margin-bottom: 0px;
    ul{
      padding: 0;
      li{
        text-align: center;
        list-style: none;
        color: #de2d63;
      }
    }
  }

  .casting-validation{
    padding-top:30px;
  }

  

  hr {
    border: solid 1px rgb(200, 204, 209);
    border-bottom: none;
    color: rgb(200, 204, 209);
  }

  .photo-upload-group {
    margin-bottom: 20px;

    h2 {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }

  .video-upload-group {
    margin-bottom: 6px;
    h2 {
      margin-top: 0px;
      margin-bottom: 1px;
    }
  }

  .input,
  .date-input,
  .numeric-input,
  .dropdown-container .dropdown-component select.dropdown {
    margin-bottom: 20px;
  }

  &-upload-button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .joined-inputs {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  p.video-header {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .photo-header {
    margin-top: 0px;
    margin-bottom: 8px;
  }

  .photo-header.error,
  .video-header.error {
    color: rgb(222, 45, 99);
  }

  .dropdown-multi-input {
    display: flex;
    align-items: flex-end;

    .icon {
      margin-left: 15px;
      margin-bottom: 15px;
      svg {
        fill: rgb(200, 204, 209);
      }

      &:hover {
        cursor: pointer;
      }

    }
    .icon.image-container{
      margin-left: 1px;
    }
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  .register {
    .panel {
      padding-top: 0;
    }
  }
}
