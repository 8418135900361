.menu-items {
  border-radius: 4px;
  background-color: #f5f6f7;

  & > div {
    border-bottom: 1px solid #c8ccd1;

    &:hover:first-child,
    &.active:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:hover:last-child,
    &.active:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &:first-child,
  &:last-child {
    border-radius: 4px;
  }
}
