.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #2d97de;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  min-width: 214px;
  outline: none;
  min-height: 38px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: 'AvenirNext-Medium', 'Avenir Next Medium', 'Avenir Next', sans-serif;
  font-weight: 500;
  font-style: normal;

  &.disabled {
    background-color: rgb(200, 204, 209);
  }

  &.inprogress-button{
    border: 1px solid #de2d63;
    background-color: #ffffff;
    color: #de2d63;
  }

  &.danger {
    background-color: #de2d63;
    border-color: #d9001b;

    &:hover {
      background-color: #e25a83;
      cursor: pointer;
    }
  }

  &.disabled {
    background-color: #d6d6d6;
  }

  &.error {
    background-color: #ffffff;
    border: 1px solid #de2d63;
    color: #de2d63;
  }

  &:not(.disabled):not(.error):not(.danger):hover {
    cursor: pointer;
  }
}
